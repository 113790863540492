import "./utils/i18n"
import "./App.css"
import { useEffect, useState } from "react"
import { changeLanguage } from "./utils/i18n"
// import Login from "pages/Login"
import Main from "pages/Main"

function App() {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    async function fetchData() {
      await changeLanguage()
      setLoading(true)
    }
    fetchData()
  }, [])
  return (
    <div>
      {/* <Login /> */}
      {loading ? <Main /> : ""}
    </div>
  )
}

export default App
