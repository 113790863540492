import { PORTAL_TYPE } from "lib/const"
export function getPermissions() {
  return localStorage.getItem("permissions") ? localStorage.getItem("permissions") : "[]"
}
export function getUserType() {
  const urlParams = new URLSearchParams(window.location.search)
  const url = urlParams.get("call_back_url")
  if (url && url.includes("driver")) {
    return PORTAL_TYPE.DRIVER
  } else if (url && url.includes("passenger")) {
    return PORTAL_TYPE.PASSENGER
  }
  return ""
}
