import { isValidPhoneNumber } from "react-phone-number-input"
import { PHONE_COUNTRY } from "lib/const"

export function isEmailValid(value) {
  if (!/\S+@\S+\.\S+/.test(value)) {
    return false
  }
  return true
}

export function isPhoneValid(code, value) {
  let phone = code + value
  if (
    (phone && isValidPhoneNumber(phone)) ||
    (code === PHONE_COUNTRY.poland.code && value.length === PHONE_COUNTRY.poland.length) ||
    (code === PHONE_COUNTRY.japan.code && value.length === PHONE_COUNTRY.japan.length)
  ) {
    return true // Valid phone number
  }
}

export function hasWhiteSpace(s) {
  return s.indexOf(" ") >= 0
}

export function isValidHttpUrl(string) {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === "http:" || url.protocol === "https:"
}

export function isValidateIP(ip) {
  // Regular expression for a valid IPv4 address
  var ipv4Regex =
    /^(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[0-1]?[0-9][0-9]?)$/

  // Regular expression for a valid IPv6 address
  var ipv6Regex =
    /^(?:[0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}$|^(?:[0-9a-fA-F]{1,4}:){1,7}:|^(?:[0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}$|^(?:[0-9a-fA-F]{1,4}:){1,5}(?::[0-9a-fA-F]{1,4}){1,2}$|^(?:[0-9a-fA-F]{1,4}:){1,4}(?::[0-9a-fA-F]{1,4}){1,3}$|^(?:[0-9a-fA-F]{1,4}:){1,3}(?::[0-9a-fA-F]{1,4}){1,4}$|^(?:[0-9a-fA-F]{1,4}:){1,2}(?::[0-9a-fA-F]{1,4}){1,5}$|^[0-9a-fA-F]{1,4}:(?:(?::[0-9a-fA-F]{1,4}){1,6})$|^(?:[0-9a-fA-F]{1,4}:){1,7}:(?:[0-9a-fA-F]{1,4}:){1,7}|^:(?::[0-9a-fA-F]{1,4}){1,7}:|^(?:[0-9a-fA-F]{1,4}:){1,7}:$/

  // Check if the given IP matches either IPv4 or IPv6 format
  return ipv4Regex.test(ip) || ipv6Regex.test(ip)
}
