/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react"
import Login from "./Login"
import SendEmail from "./SendEmail"
import OTPCode from "./OTPCode"
import SendPhoneNumber from "./SendPhoneNumber"
import OTPCodePhone from "./OTPCodePhone"
import ResetPassword from "./ResetPassword"
import PasswordSuccess from "./PasswordSuccess"
import { EMAIL_TYPE } from "lib/const"
import logoImg from "../icon/logo.svg"
import { getUserType } from "utils/utils"

const Main = () => {
  const [step, setStep] = useState(1)
  const [firstLogin, setFirstLogin] = useState(false)
  const [isLockedUser, setIsLockedUser] = useState(false)
  const [email, setEmail] = useState("")
  // const [phone, setPhone] = useState("")
  // const [phoneCode, setPhoneCode] = useState("")
  const [type, setType] = useState(EMAIL_TYPE.Reset)
  const [auth, setAuth] = useState(null)
  // const userType = getUserType()

  function onActionFromGetError(isLockedUser) {
    if (isLockedUser) {
      setType(EMAIL_TYPE.Reset)
    } else {
      setType(EMAIL_TYPE.Confirm)
    }
    setIsLockedUser(isLockedUser)
    setFirstLogin(!isLockedUser)
    setStep(2)
  }

  function onForgot() {
    setType(EMAIL_TYPE.Reset)
    setFirstLogin(false)
    setStep(2)
  }

  function onConfirmOTP(auth) {
    // if (userType) {
    //   setStep(4)
    // } else {
    // }
    setAuth(auth)
    setStep(4)
  }

  function onSendEmail(email) {
    setEmail(email)
    setStep(3)
  }

  // function onSendPhoneNumber(phoneCode, phone) {
  //   setPhoneCode(phoneCode)
  //   setPhone(phone)
  //   setStep(5)
  // }

  // function onConfirmOTPPhone(auth) {
  //   setAuth(auth)
  //   setStep(6)
  // }

  function onBackLogin() {
    setStep(1)
  }

  return (
    <section>
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        {step === 1 && (
          <div>
            <img src={logoImg} alt="logo" className="logo" />
          </div>
        )}
        <div className="w-full bg-white md:mt-0 sm:max-w-md xl:p-0">
          {step === 1 && <Login onNext={onActionFromGetError} onForgot={onForgot} />}
          {step === 2 && (
            <SendEmail
              isFirstLogin={firstLogin}
              isLockedUser={isLockedUser}
              onNext={onSendEmail}
              onBack={onBackLogin}
            />
          )}
          {step === 3 && <OTPCode type={type} email={email} onNext={onConfirmOTP} />}
          {/* {step === 4 && (
            <SendPhoneNumber
              isFirstLogin={firstLogin}
              isLockedUser={isLockedUser}
              onNext={onSendPhoneNumber}
              onBack={onBackLogin}
            />
          )}
          {step === 5 && (
            <OTPCodePhone
              type={type}
              phoneCode={phoneCode}
              phone={phone}
              onNext={onConfirmOTPPhone}
            />
          )} */}
          {step === 4 && <ResetPassword auth={auth} onNext={() => setStep(7)} />}
          {step === 5 && <PasswordSuccess onNext={onBackLogin} />}
        </div>
      </div>
    </section>
  )
}

export default Main
