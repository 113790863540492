import axios from "axios"
import createAuthRefreshInterceptor from "axios-auth-refresh"
// import { getAuthLocal } from "lib/localstorage"
import { getCookieAuth } from "lib/cookie"
import { v4 as uuidv4 } from "uuid"

const globalId = uuidv4()
const refreshAuthLogic = (failedRequest) =>
  axios
    .post(`${process.env.REACT_APP_DOMAIN}auth/refresh`, {
      refresh_token: getCookieAuth()?.refresh_token
    })
    .then((tokenRefreshResponse) => {
      localStorage.setItem("accessToken", tokenRefreshResponse.data.token)

      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + tokenRefreshResponse.data.token

      return Promise.resolve()
    })
    .catch(() => {
      localStorage.clear()
      // axios.delete("/auth/register", { token: getRefreshToken() })
      window.location.href = "/login"
    })

export default function getInstanceAxios(baseAPI, configHeader) {
  // const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const instance = axios.create({
    // withCredentials: true,
    baseURL: baseAPI
  })

  instance.interceptors.request.use(
    function (config) {
      config.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Http-global-id": globalId
      }
      if (configHeader?.projectId) config.headers["project-id"] = configHeader?.projectId
      if (getCookieAuth()?.access_token)
        config.headers["Authorization"] = `Bearer ${getCookieAuth()?.access_token}`
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  instance.interceptors.response.use(
    function (response) {
      try {
        if (response.status >= 200 && response.status < 300) return response.data
        return Promise.reject(response.data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async function (error) {
      if (error.response) {
        const { response } = error
        const data = response.data
        return Promise.reject(data)
        // if (data && response.config.method !== "get" && response.status !== 500) {
        //   if (data.error_message) {
        //     // notification.error({
        //     //   message: convertMessageError(data.error_message),
        //     //   duration: 3
        //     // })
        //     return Promise.reject(data.error_message)
        //   }
        //   if (data.message) {
        //     // notification.error({
        //     //   message: data?.message || data?.error_message
        //     // })
        //     return
        //   }
        //   if (data && !data.message && Object.values(data).length > 0) {
        //     if (data.length > 0) {
        //       data.forEach((item) => {
        //         // notification.error({
        //         //   message: item,
        //         //   duration: 3
        //         // })
        //       })
        //     } else {
        //       Object.values(data).forEach((item) => {
        //         // notification.error({
        //         //   message: item?.length > 0 ? item[0] : "N/A",
        //         //   duration: 3
        //         // })
        //       })
        //     }
        //   } else {
        //     // notification.error({
        //     //   message: data?.message || data?.error_message
        //     // })
        //   }
        // }
      }
      return Promise.reject(error)
    }
  )

  createAuthRefreshInterceptor(instance, refreshAuthLogic)
  return instance
}

function convertMessageError(errors) {
  var str = []
  for (let i = 0; i < errors.length; i++) {
    let k = Object.keys(errors[i])[0]
    str.push(k + " : " + errors[i][k])
  }
  return str.map((i) => {
    return <div>{i}</div>
  })
}
