import Cookies from "js-cookie"
var parts = window.location.hostname.split(".")
var sndleveldomain = parts.slice(-2).join(".")
const config = {
  domain: sndleveldomain
}
export function setEnums(enums) {
  let saveEnums = {}
  if (enums && Object.keys(enums)?.length) {
    saveEnums = enums
  }
  Cookies.set("enums", JSON.stringify(saveEnums), config)
}
export function getEnums() {
  const enums = Cookies.get("enums", config)
  if (enums) return JSON.parse(enums)
  return {}
}
export function setCookieAuth(auth) {
  let authSave = {}
  if (auth && Object.keys(auth)?.length) {
    authSave = auth
  }
  Cookies.set("auth", JSON.stringify(authSave), config)
}
export function getCookieAuth() {
  const v = Cookies.get("auth", config)
  if (v) return JSON.parse(v)
  return {}
}

export function removeCookies() {
  // Cookies.remove("userInfo", config)
  Cookies.remove("auth", config)
  Cookies.remove("enums", config)
}

export function getCookieCallbackUrl() {
  const v = Cookies.get("call_back_url", config)
  if (v) return v
  return null
}
