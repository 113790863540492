/* eslint-disable jsx-a11y/anchor-is-valid */
import { apiUser } from "api"
import { useState } from "react"
import CustomButton from "./CustomButton"
import { iconPhone } from "lib/image"
import "./index.css"
import { useTranslation } from "react-i18next"
import { getUserType } from "utils/utils"

const OTPCode = ({ email, type, onNext }) => {
  const { t } = useTranslation()
  const [code, setCode] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const userType = getUserType()

  const onFinish = async (e) => {
    if (loading) {
      return
    }
    e.preventDefault()
    let res = {}
    try {
      setLoading(true)
      if (userType) {
        res = await apiUser.verifyCodeEndUser({
          email: email.trim(),
          code: code,
          mail_type: type
        })
      } else {
        res = await apiUser.verifyCode({
          email: email.trim(),
          code: code,
          mail_type: type
        })
      }
      const { access_token, refresh_token, organization_id } = res.data
      onNext({
        organization_id,
        access_token,
        refresh_token
      })
    } catch (error) {
      const { error_message } = error
      setError(error_message[0])
    } finally {
      setLoading(false)
    }
  }

  const onChange = (v) => {
    setCode(v)
  }

  const onResend = async () => {
    if (loading) {
      return
    }

    try {
      setLoading(true)
      if (userType) {
        await apiUser.sendEmailEndUser({
          email: email.trim(),
          mail_type: type
        })
      } else {
        await apiUser.sendEmail({
          email: email.trim(),
          mail_type: type
        })
      }
    } catch (error) {
      const { error_message } = error
      setError(error_message[0])
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
        <h1 className="text-[30px] text-[#171A1F] font-[700] leading-tight tracking-tight text-center">
          {t("79_456")}
        </h1>
        <div className="margin-text-top text-center text-[14px] font-[400] text-[#171A1F]">
          {t("79_457")}
        </div>
        <form className="space-y-4 md:space-y-6" onSubmit={onFinish}>
          <div>
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-[#000000A6]">
              {t("79_468")}
            </label>
            <input
              onChange={(e) => onChange(e.target.value)}
              onFocus={(e) => setError("")}
              value={code}
              type="text"
              name="code"
              id="code"
              className="pl-9 border border-gray-300 text-gray-900 text-[16px] font-[400] rounded-[4px] block w-full p-2.5 placeholder:text-input-box placeholder:text-[#B5B5B5E0]"
              placeholder={t("79_463")}
              required
            />
            <span className="relative float-left left-2 -top-[34px]">{iconPhone}</span>
          </div>
          {error !== "" && <div className="text-red-500">{error}</div>}
          <div
            className="text-[14px] hover:underline hover:cursor-pointer text-[#000000E0] text-center"
            onClick={onResend}
          >
            {t("79_458")}
          </div>
          <CustomButton
            title={t("79_460")}
            loading={loading}
            disabled={error !== "" ? true : false}
          />
        </form>
      </div>
    </div>
  )
}

export default OTPCode
