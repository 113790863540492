import { configLocale } from "lib/const"
import Client from "../client/ClientAms"

const resource = "/api"

function getTranslate(params) {
  return Client("root_all_resources").get(
    `${resource}/sdk/translation-elements/latest-version-v2`,
    {
      params: {
        design_file_id: configLocale.designFileId,
        limit: 20000,
        ...params
      }
    }
  )
}

const api = {
  getTranslate
}

export default api
