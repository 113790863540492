/* eslint-disable jsx-a11y/anchor-is-valid */
import { apiUser } from "api"
import { useEffect, useState } from "react"
import { setCookieAuth, getCookieCallbackUrl } from "lib/cookie"
import { isEmailValid } from "lib/validate"
import { ERROR_API_CODE, PORTAL_TYPE } from "lib/const"
import CustomButton from "./CustomButton"
import "./index.css"
import { iconHide, iconShow, iconEmail, iconPassword } from "lib/image"
import { useTranslation } from "react-i18next"
import { convertPermission } from "lib/function"
import { RESOURCE_KEY } from "lib/const"
import { jwtDecode } from "jwt-decode"
// import { getUserType } from "utils/utils"

const Login = ({ onNext, onForgot }) => {
  const { t } = useTranslation()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [showPass, setShowPass] = useState(false)
  const [loading, setLoading] = useState(false)
  let tabId = sessionStorage.tabID

  const onChange = (v) => {
    setError("")
    setUsername(v)
  }

  const getPortalUrl = (permission) => {
    if (permission[RESOURCE_KEY.ROOT_DASHBOARD]?.GET) {
      return process.env.REACT_APP_CLIENT_IDM_URL
    }
    if (permission[RESOURCE_KEY.ROOT_PROJECT_RESOURCE]?.GET) {
      return process.env.REACT_APP_CLIENT_DEVELOP_CONSOLE_URL
    }
    if (permission[RESOURCE_KEY.ROOT_SERVICE]?.GET) {
      return process.env.REACT_APP_CLIENT_ADMIN_PORTAL_URL
    }
    if (permission[RESOURCE_KEY.ROOT_REPORT]?.GET) {
      return process.env.REACT_APP_CLIENT_CLIENT_PORTAL_URL
    }
  }

  const onFinish = async (e) => {
    e.preventDefault()
    if (!isEmailValid(username)) {
      setError("Email is invalid!")
      return
    }

    // const urlParams = new URLSearchParams(window.location.search)
    let res = {}
    try {
      setLoading(true)
      res = await apiUser.login({
        username: username.toLocaleLowerCase(),
        password
      })

      const { access_token, refresh_token } = res?.data
      let auth = {
        access_token,
        refresh_token
      }
      setCookieAuth(auth)
      let call_back_url = getCookieCallbackUrl()
      const userType = jwtDecode(access_token)?.user_type
      if (userType) {
        if (userType === "DRIVER") {
          call_back_url = process.env.REACT_APP_CLIENT_DRIVER_PORTAL_URL
        }
        if (userType === "PASSENGER") {
          call_back_url = process.env.REACT_APP_CLIENT_PASSENGER_PORTAL_URL
        }
      }

      if (!call_back_url) {
        setTimeout(async () => {
          const permission = await apiUser.getPermissions()
          const _permission = convertPermission(permission?.data)
          call_back_url = getPortalUrl(_permission)
          window.location.href = call_back_url + "?tabId=" + tabId
        }, 2000)
      } else {
        window.location.href = call_back_url + "?tabId=" + tabId
      }
    } catch (error) {
      const { error_code, error_message } = error
      if (error_code === ERROR_API_CODE.User_Verify) {
        onNext(false)
      } else if (error_code === ERROR_API_CODE.User_Locked) {
        onNext(true)
      } else {
        if (error_message && Array.isArray(error_message) && error_message.length > 0) {
          setError(error_message[0])
        } else {
          setError("Something went wrong!")
        }
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!tabId) {
      sessionStorage.tabID = Math.random()
    }

    if (error) setError("")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, password])

  return (
    <div>
      <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
        <form className="space-y-4 md:space-y-6" onSubmit={onFinish}>
          <div>
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-[#000000A6]">
              {t("79_377")}
            </label>
            <input
              onChange={(e) => onChange(e.target.value)}
              onFocus={(e) => setError("")}
              value={username}
              type="email"
              name="email"
              id="email"
              className="pl-9 border border-gray-300 text-gray-900 text-[16px] font-[400] rounded-[4px] block w-full p-2.5 placeholder:text-input-box placeholder:text-[#B5B5B5E0]"
              placeholder={t("79_373")}
              required
            />
            <span className="relative float-left left-2 -top-[34px]">{iconEmail}</span>
          </div>
          <div>
            <label htmlFor="password" className="block mb-2 text-sm font-medium text-[#000000A6]">
              {t("79_385")}
            </label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              onFocus={(e) => setError("")}
              value={password}
              type={showPass ? "text" : "password"}
              name="password"
              id="password"
              placeholder={t("79_380")}
              className="pl-9 border border-gray-300 text-gray-900 text-[16px] font-[400] rounded-[4px] block w-full p-2.5 placeholder:text-input-box placeholder:text-[#B5B5B5E0]"
              required
            />
            <span className="relative float-left left-1 -top-[35px]">{iconPassword}</span>
            {password.length > 0 && (
              <span className="field-icon cursor-pointer" onClick={() => setShowPass(!showPass)}>
                {showPass ? iconShow : iconHide}
              </span>
            )}
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="flex items-center h-5">
                <input
                  id="remember"
                  aria-describedby="remember"
                  type="checkbox"
                  className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800"
                  required=""
                />
              </div>
              <div className="ml-3">
                <label htmlFor="remember" className="text-[14px] text-[#000000E0] font-[400]">
                  {t("79_391")}
                </label>
              </div>
            </div>
          </div>
          {error !== "" && <div className="margin-text-top text-red-500">{error}</div>}
          <CustomButton title="Sign In" loading={loading} disabled={error !== "" ? true : false} />
          <div
            className="text-[14px] hover:underline hover:cursor-pointer text-[#000000E0] text-center"
            onClick={onForgot}
          >
            {t("79_393")}
          </div>
          <div>
            <p className="text-center text-[14px] text-[#000000A6] font-[400]">
              {t("79_399")} {t("79_400")}
            </p>
            <p className="text-center">
              <span className="hover:cursor-pointer underline text-[14px] text-[#000000A6] font-[400]">
                {t("111_17")}
              </span>
              <span className="m-5">/</span>
              <span className="hover:cursor-pointer underline text-[14px] text-[#000000A6] font-[400]">
                {t("111_18")}
              </span>
            </p>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login
