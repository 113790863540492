import Client from "../client/Client"
import Auth from "../auth/Auth"
import version from "../version"

const login = (data) => {
  return Client.post(`/api/v1/account/login/`, data)
}
const loginEnduser = (data) => {
  return Client.post(`/api/v1/account/eu/login/`, data)
}
function gets(data) {
  return Client.get(`${version}/slides`, data)
}
function create(data) {
  return Client.post(`${version}/slides/`, data)
}
function update(id, data) {
  return Client.put(`${version}/account/password/${id}/`, data)
}
function remove(id) {
  return Client.delete(`${version}/account/password/${id}/`)
}
function getMenus(data) {
  return Client.get(`${version}/internal/menus/`, data)
}
function getEnums(data) {
  return Client.get(`${version}/internal/enum_seleted_data/`)
}
function getUserInfo(data) {
  return Client.get(`${version}/account/view/profile/`, data)
}
function sendEmail(data) {
  return Client.post(`${version}/mail/generate-code/`, data)
}
function sendEmailEndUser(data) {
  return Auth.post(`${version}/mail/generate-code/`, data)
}
function sendPhoneNumber(data) {
  return Auth.post(`${version}/phone/generate-code`, data)
}
function verifyCode(data) {
  return Client.post(`${version}/mail/verify-code/`, data)
}
function verifyCodeEndUser(data) {
  return Auth.post(`${version}/mail/verify-code/`, data)
}
function verifyCodePhoneNumber(data) {
  return Auth.post(`${version}/phone/verify-code/`, data)
}
function resetPassword(data) {
  return Client.put(`${version}/account/reset-password/`, data)
}

function getPermissions(params) {
  return Client.get(`/api/v1/account/permissions/`, {
    params
  })
}

const api = {
  login,
  loginEnduser,
  gets,
  create,
  update,
  remove,
  getMenus,
  getUserInfo,
  getEnums,
  sendEmail,
  sendPhoneNumber,
  verifyCode,
  verifyCodePhoneNumber,
  resetPassword,
  sendEmailEndUser,
  verifyCodeEndUser,
  getPermissions
}
export default api
