export function convertPermission(permission) {
  const list = Object.keys(permission)
  let objPermission = {}
  for (let i = 0; i < list.length; i++) {
    let __path = list[i]?.split(".")[0]
    let values = permission[list[i]]
    if (!objPermission[__path]) objPermission[__path] = {}
    for (let i = 0; i < values.length; i++) {
      if (values[i].method === "*") {
        objPermission[__path] = {
          DELETE: true,
          POST: true,
          PUT: true,
          GET: true,
          CLOSE: true,
          DETAIL: true,
          ATRACT: true,
          APPROVAL: true,
          VIEW: true,
          IMPORT: true,
          EXPORT: true
        }
        break
      } else {
        objPermission[__path][values[i].method] = true
      }
    }
  }
  return objPermission
}
